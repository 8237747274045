.experience_container {
    display: flex;
    justify-content: center;
    gap: 3rem;
    padding: 2rem;
  }
  
  .experience_frontend,
  .experience_backend {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
  }
  
  .experience_frontend:hover,
  .experience_backend:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
  }
  
  .experience_frontend h3,
  .experience_backend h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
  }
  
  .experience_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
  }
  
  .experience_details {
    display: grid;
    gap: 1rem;
  }
  
  .experience_details-icon {
    margin-top: 6px;
    color: var(--color-primary);
  }
  
  /* #################### MEDIA QUERIES (MEDIUM DEVICES) #################### */
  @media screen and (max-width: 1024px) {
    .experience_container {
      flex-direction: column;
      align-items: center;
    }
  
    .experience_container {
      width: 80%;
      padding: 2rem;
      margin: 0 auto;
    }
  
    .experience_content {
      padding: 1rem;
    }
  }
  
  /* #################### MEDIA QUERIES (SMALL DEVICES) #################### */
  @media screen and (max-width: 600px) {
    .experience_container {
      gap: 1rem;
    }
  
    .experience_frontend,
    .experience_backend {
      width: 100%;
      padding: 2rem 1rem;
    }
  }