.about_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding: 2rem;
    text-align: center;
    max-width: 100%; /* Ensure the container doesn't exceed the width of its parent */
}

.about_us {
    width: 40%; /* Set width to 100% */
    max-width: 60%; /* Adjust maximum width */
    max-height: 80%; /* Adjust maximum height */
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-bg-variant), transparent);
    overflow: hidden;
    transition: var(--transition);
    display: flex;
    justify-content: center;
    align-items: center;
}

.about_us-image {
    width: 90%;
    height: 50%;
    max-width: 90%;
    max-height: 50%;
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(-10deg);
    transition: var(--transition);
}

.about_us-image:hover {
    transform: rotate(0);
}

.about_cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    padding: 2rem;
    justify-content: center;
    max-width: 100%; /* Ensure the grid doesn't exceed the width of its parent */
}

.about_card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
}

.about_card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: pointer;
}

.about_icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about_card h5 {
    font-size: 0.95rem;
}

.about_card small {
    font-size: 0.7rem;
    color: var(--color-light);
}

.about_content p {
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
    max-width: 100%; /* Ensure the paragraph doesn't exceed the width of its parent */
    padding: 0 1rem; /* Add padding to prevent text from sticking to the edges */
}

/* #################### MEDIA QUERIES (MEDIUM DEVICES) #################### */
@media screen and (max-width: 1024px) {
    .about_container {
        gap: 2rem;
    }

    .about_us {
        width: 100%;
        margin: 2rem auto 4rem;
    }

    .about_content p {
        width: 300px; /* Adjust width to make it wider on smaller screens */
        margin: 0 auto; /* Center the paragraph horizontally */
        padding: 0 1rem; /* Adjust padding for smaller screens */
    }
}

/* #################### MEDIA QUERIES (SMALL DEVICES) #################### */
@media screen and (max-width: 600px) {
    .about_us {
        width: 100%;
        margin: 0 auto 3rem;
    }

    .about_cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
}

@media screen and (max-width: 600px) {
    .about_content p {
        width: 300px; /* Adjust width to make it wider on smaller screens */
        margin: 0 auto; /* Center the paragraph horizontally */
        padding: 0 1rem; /* Adjust padding for smaller screens */
    }
}

