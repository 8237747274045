/* header.css */
header {
    text-align: center;
    padding-top: 4rem;
    overflow: hidden;
    position: relative;
}

.header_container .image-switcher {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .header_container .image-switcher button {
    background-color: var(--color-primary);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .header_container .image-switcher button:hover {
    background-color: var(--color-primary-variant);
  }
  
.header_container {
    text-align: center;
    height: 100%;
    position: relative;
}

.mbes {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto; /* Center horizontally */
    width: 600px; /* Adjust the width as desired */
    max-width: 100%;
    height: auto; /* This will maintain the aspect ratio */
    overflow: hidden;
    margin-bottom: 1rem; /* Small gap after the image */
}

.mbes img {
    max-width: 100%; /* Make the image take up the full width of the .mbes div */
    height: auto; /* Maintain aspect ratio */
}

h2 {
    margin-bottom: 1rem; /* Small gap below the title */
}

h3 {
    margin-top: 0; /* Remove any default top margin */
    margin-bottom: 0.5rem; /* Small gap below the title */
}

h4 {
    margin-top: 0; /* Remove any default top margin */
    margin-bottom: 1rem; /* Small gap below the subtitle */
}

.text-box {
    background: var(--color-bg-variant);
    padding: 2.4rem 2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    width: 100%;
    margin: 0 auto;
    text-align: center;
    box-sizing: border-box;
    max-width: 60%;
    position: relative;
    z-index: 1;
    margin-bottom: 1rem; /* Small gap below the text box */
}

.text-box:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.text-box h3 {
    text-align: center;
    color: var(--color-primary);
}

.cta {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 0rem;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    flex-direction: unset;
}

.header_socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 1rem;
    bottom: 5rem;
    font-size: 28px;
}

.header_socials::after {
    content: '';
    width: 2px;
    height: 2rem;
    background: var(--color-primary);
}

.scroll_down {
    position: absolute;
    right: 0.02rem;
    bottom: 10rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}

@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    section>h2 {
        margin-bottom: 2rem;
    }
}