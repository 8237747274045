.title_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    gap: 0.5rem;
    padding: 2rem;
  }

.themes_container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    justify-content: center; /* Added this line */
    gap: 0.5rem;
    padding: 2rem;
  }
  
  /* #################### MEDIA QUERIES (MEDIUM DEVICES) #################### */
  @media screen and (max-width: 1024px) {
    .themes_container {
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      justify-content: center; /* Added this line */
      gap: 0.8rem;
    }
  }
  
  /* #################### MEDIA QUERIES (SMALL DEVICES) #################### */
  @media screen and (max-width: 600px) {
    .themes_container {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      justify-content: center; /* Added this line */
      gap: 0.5rem;
    }
  }