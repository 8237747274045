.portfolio_container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2.5rem;
    padding: 2rem;
  }
  
  .portfolio_item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    display: flex;
    flex-direction: column;
  }

  .portfolio_item-icon {
    color: var(--color-primary-variant);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }  
  
  .portfolio_item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
  }
  
  .portfolio_item-image {
    border-radius: 1.5rem 1.5rem 0 0;
    overflow: hidden;
  }
  
  .portfolio_item-image img {
    width: 100%;
    height: auto;
  }
  
  .portfolio_item h3 {
    margin: 0.5rem 0 0rem;
    text-align: center;
  }

  .portfolio_item-description {
    text-align: center;
    margin: 2rem 0 0rem;
    flex-grow: 1; /* Allow the description to take up remaining vertical space */
  }
  
  
  /* #################### MEDIA QUERIES (MEDIUM DEVICES) #################### */
  @media screen and (max-width: 1024px) {
    .portfolio_container {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 1.2rem;
    }
  }
  
  /* #################### MEDIA QUERIES (SMALL DEVICES) #################### */
  @media screen and (max-width: 600px) {
    .portfolio_container {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }