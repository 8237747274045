.services_container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 2rem;
    justify-content: center;
  }
  
  .service {
    background: var(--color-bg-variant);
    border-radius: 0 0 2rem 2rem;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    display: flex;
    flex-direction: column;
  }
  
  .service:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
  }
  
  .service_head {
    background: var(--color-primary);
    padding: 2rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .service_head h3 {
    color: var(--color-bg);
    font-size: 1rem;
    margin: 0.5rem 0;
  }
  
  .service_list {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Allow the service_list to take up remaining vertical space */
  }
  
  .service_list p {
    font-size: 0.9rem;
    margin: 0;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .service_head .service_icon {
    color: var(--color-primary-variant);
    margin-bottom: 1rem;
  }
  
  /* #################### MEDIA QUERIES (MEDIUM DEVICES) #################### */
  @media screen and (max-width: 1024px) {
    .services_container {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 1.2rem;
    }
  }
  
  /* #################### MEDIA QUERIES (SMALL DEVICES) #################### */
  @media screen and (max-width: 600px) {
    .services_container {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }